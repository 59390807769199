<template>
  <div>
    <div class="lDiv">
      <label>公司名称：</label>
      <label style="margin-left: 10px;">{{dataValue.rowObj['companyName']}}</label>
    </div>
    <div class="lDiv">
      <div style="margin-bottom: 8px;">发送运营邮件，我的邮件（{{ dataValue.sendMail.fromEmail }}）：</div>
      <a-input v-model:value="dataValue.sendMail.toers" placeholder="收件人" />
      <a-input  style="margin-top: 5px;" v-model:value="dataValue.sendMail.subject" placeholder="主题" />
      <a-textarea style="margin-top: 5px; height: 300px;" placeholder="邮件内容"
                  v-model:value="dataValue.sendMail.body" />
    </div>

    <a-popconfirm title="请确认您的邮件内容？" ok-text="是" cancel-text="否" @confirm="allEvent.sendMail">
      <a-button type="primary" :disabled="dataValue.sendMailType">{{ dataValue.sendMailText }}</a-button>
    </a-popconfirm>
  </div>
</template>

<script>
import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";

export default {
  name : "up2_bom_show_email",
  props : [ 'row_email_obj', 'my_role_type' ],
  emits : ['goMClose'],
  setup(props, { emit }) {

    let dataValue = reactive({
      rowObj : props.row_email_obj,
      myRoleType : props.my_role_type,
      sendMailType : false,
      sendMailText : "发送邮件",
      sendMail : {
        employeeName : "",
        fromEmail : "",
        fromPasswd : "",
        toers : "",
        ccers : "",
        subject : "",
        body : ""
      }
    });

    let allEvent = {

      sendMail : ()=>{

        dataValue.sendMailType = true;
        dataValue.sendMailText = "邮件发送中 ... ";

        proxy.newApi('/uplater/mail/send', dataValue.sendMail ).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          }else{
            message.info( "邮件提交成功，请勿重复提交发送！" );
          }

          dataValue.sendMailType = false;
          dataValue.sendMailText = "发送邮件";
        });

      },

      setDefaultMail : ()=>{

        if (dataValue.rowObj['linkmenEmail'].length > 5) {
          dataValue.sendMail.toers += dataValue.rowObj['linkmenEmail'] + ",";
        }
        if (dataValue.rowObj['companyEmail'].length > 5) {
          dataValue.sendMail.toers += dataValue.rowObj['companyEmail'] + ",";
        }

        dataValue.sendMail.subject = "关于 "+ dataValue.rowObj['modelName'] +" 贵司还有用到吗？";

        if( dataValue.myRoleType === 1 ) // 我是库存
        {
          dataValue.sendMail.body = ""+

              dataValue.rowObj['companyName'] +":\n\n"+
              "       您好，我是闲芯的"+ dataValue.sendMail.employeeName +"。\n\n"+
              "       今天有了解到到 "+ dataValue.rowObj['modelName'] +" 贵司有用到，请问现在这颗料有库存可以出售吗？或者贵司有哪些品牌价格比较好的，也长期在和原厂或代理在定，我们可以加单的？\n\n"+
              "       期待您的回复，顺祝商祺！\n\n"+
              "       "+ dataValue.sendMail.employeeName +"\n"+
              "       "+ new Date().toLocaleDateString() +
              "";
          }

          if( dataValue.myRoleType === 2 ) // 我是销售
          {
            dataValue.sendMail.body = ""+

                dataValue.rowObj['companyName'] +":\n\n"+
                "       您好，我是闲芯的"+ dataValue.sendMail.employeeName +"。\n\n"+
                "       今天有了解 "+ dataValue.rowObj['modelName'] +" 这颗物料贵司之前有用到过，想问下您目前这颗料还在用吗？或者贵司有其他缺料以及降本计划都可以拿来匹配我们3000家工厂货源，会尽量给您合理的价格。\n\n"+
                "       期待您的回复，顺祝商祺！\n\n\n"+
                "       "+ dataValue.sendMail.employeeName +"\n"+
                "       "+ new Date().toLocaleDateString() +
                "";
          }
      },

      getMyInfo : ()=>{

        proxy.newApi('/organization/employee/get', {} ).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue.sendMail.employeeName = res["data"]["employeeName"];
            dataValue.sendMail.fromEmail    = res["data"]["email"] +"@chipslinking.com";
            dataValue.sendMail.fromPasswd   = res["data"]["emailPass"];

            allEvent.setDefaultMail();
          }
        });

      },

      init : ()=> {
        allEvent.getMyInfo();
      }
    }

    allEvent.init();

    return { dataValue, allEvent }
  }
}
</script>

<style scoped>
.lDiv{ clear: both; margin-bottom: 20px; }
</style>