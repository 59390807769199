<template>

  <div class="divSearch" v-if="dataValue.bomList.loading === false">

    <a-input class="model" placeholder="型号名称" @pressEnter="allEvent.getBomList.toPage(1)"
             v-model:value="dataValue.bomList.input.modelName"/>

    <a-select class="opEmployee" v-model:value="dataValue.bomList.input.myRoleType"
              @change="allEvent.getBomList.http">
      <a-select-option :value="2" v-if="tfun.check(11,12)">匹配-库存数据</a-select-option>
      <a-select-option :value="1" v-if="tfun.check(10)">匹配-销售数据</a-select-option>
    </a-select>

    <a-button class="buttSearch" type="primary" @click="allEvent.getBomList.toPage(1)">
      <search-outlined/>
    </a-button>

  </div>

  <a-spin class="loadingClass" v-if="dataValue.bomList.loading === true" tip="正在加载数据 ..."></a-spin>
  <div v-if="dataValue.bomList.loading === false" class="divTab">
    <table class="ahuiTable">
      <tr>
        <th>编号</th>
        <th>单号</th>
        <th>订单来源</th>
        <th>记录数</th>
        <th>上传人</th>
        <th>匹配公司</th>
        <th>匹配型号数</th>
        <th>匹配公司数</th>
        <th>创建时间</th>
        <th></th>
      </tr>

      <tr v-for="aRow in dataValue.bomList.output.list" :style="allEvent.rowColor.selectRowColor(aRow['indexId'])"
          @click="allEvent.rowColor.selectRowEvent(aRow['indexId'])" >
        <td class="min">{{ aRow['indexId'] }}</td>
        <td class="min">{{ aRow['billCode'] }}</td>
        <td class="min">{{ aRow['billTypeName'] }}</td>
        <td class="min">{{ aRow['upSize'] }}</td>
        <td class="min">{{ aRow['employeeName'] }}</td>
        <td>{{ aRow['pp_show'] }}</td>
        <td class="min">{{ aRow['pp_billCount'] }}</td>
        <td class="min">{{ aRow['pp_companyCount'] }}</td>
        <td class="min">{{ aRow['createTime'] }}</td>
        <td class="min">
          <a class="set2" v-if="aRow['pp_billCount'] > 0" @click="allEvent.showDetail.goOpen(aRow)">查看</a>
        </td>
      </tr>
      <tr>
        <td colspan="100" class="pages">
          <APages :number_of_page="dataValue.bomList.input.pageOfNumber"
                  :this_page="dataValue.bomList.input.page" :all_page="dataValue.bomList.output.allPage"
                  @toPage="allEvent.getBomList.toPage" :all_count="dataValue.bomList.output.rowCount"
          />
        </td>
      </tr>
    </table>

  </div>

  <a-drawer
      title="查看匹配详细信息"
      width="1280"
      :visible="dataValue.showDetail.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.showDetail.goClose"
  >
    <up2BomShow :row_obj="dataValue.showDetail.rowObj" :my_role_type="dataValue.bomList.input.myRoleType" @goClose="allEvent.showDetail.goClose"/>
  </a-drawer>

</template>

<script>

import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";
import APages from "@/tools/pages"
import up2BomShow from "@/view/uplater/up2_bom_show";
import tfun from "@/tools/tfun";

export default {
  name : "up_bom_list",
  emits : [ 'setMenuKey' ],
  components : { APages, up2BomShow },
  setup(props, { emit }) {

    emit('setMenuKey', 'yy.ppwdbmlb');

    let dataValue = reactive({

      showDetail :{
        visible : false,
        rowObj : null
      },

      bomList : {

        loading : false,
        selectRow : 0,

        input : {
          "page" : 1,
          "pageOfNumber" : 10,
          "myRoleType" : 0,
          "modelName" : ""
        },
        output : {
          "rowCount" : 0,
          "allPage" : 6,
          "list" : []
        }
      },

      getModel : {

        input : {
          "modelNames": [],
          "searchRoleType": 0
        }

      }

    })

    let allEvent = {

      rowColor : {
        selectRowColor : (rowId) => {
          return dataValue.bomList.selectRow === rowId ? "background-color:#d8ffd8;" : "";
        },
        selectRowEvent : (rowId) => {
          dataValue.bomList.selectRow = rowId;
        }
      },

      showDetail : {

        goOpen :(row)=>{
          dataValue.showDetail.visible = true;
          dataValue.showDetail.rowObj = row;
        },
        goClose : ()=>{
          dataValue.showDetail.visible = false;
        }

      },

      getModel : {

        allList : ()=>{

          for( let i=0; i<dataValue.bomList.output.list.length; i++ ){

            let aBom = dataValue.bomList.output.list[i];

            dataValue.getModel.input.modelNames = aBom.models;
            dataValue.getModel.input.searchRoleType = dataValue.bomList.input.myRoleType;
            aBom.pp_billCount = "加载中 ...";
            aBom.pp_companyCount = "加载中 ...";
            aBom.pp_show = "加载中 ...";


            proxy.newApi('/uplater/bom2/get.model', dataValue.getModel.input).then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                aBom.pp_billCount    = res.data['billCount'];
                aBom.pp_companyCount = res.data['companyCount'];
                aBom.pp_companyList  = res.data['companyList'];
                aBom.pp_list         = res.data['list'];

                aBom.pp_show = ""
                for( let z=0; z<aBom.pp_companyList.length&&z<30; z++ ){
                  aBom.pp_show += aBom.pp_companyList[z]+";   ";
                }
                if( aBom.pp_list.length > 30 ) aBom.pp_show += "... ..."

              }
            })

          }
        }

      },

      getBomList : {
        http : () => {
          dataValue.bomList.loading = true;
          dataValue.bomList.input.myOpEmployee = dataValue.bomList.input.myOpEmployeeInt === 1;
          proxy.newApi('/uplater/bom2/get.list', dataValue.bomList.input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.bomList.output.allPage = res.data["allPage"];
              dataValue.bomList.output.rowCount = res.data["rowCount"];
              dataValue.bomList.output.list = res.data["list"];
              dataValue.bomList.loading = false;

              allEvent.getModel.allList();
            }
          })
        },

        toPage : (pageId) => {
          dataValue.bomList.input.page = pageId;
          allEvent.getBomList.http();
        }
      },

      init : () => {

        if( tfun.check(10) === true ){
          dataValue.bomList.input.myRoleType = 1;
        } else if( tfun.check(11,12) === true ){
          dataValue.bomList.input.myRoleType = 2;
        }


        allEvent.getBomList.http();

      }

    }

    allEvent.init();

    return {
      tfun, dataValue, allEvent
    }

  }
}
</script>


<style scoped>
.divSearch { left: 0; width: 100%; padding: 10px; }
.divSearch .model { width: 300px; }
.divSearch .opEmployee { margin-left: 10px; }
.divSearch .buttSearch { margin-left: 10px; }
.divTab { width: 100%; padding: 0 10px 10px 10px; }
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
.set1{ color: #1a5f91; float: right; }
.set2{ margin-left:0; }
.min2{ width: 80px; }
</style>