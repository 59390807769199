<template>
  <div class="onlyMailDiv">
    <a-checkbox v-model:checked="dataValue.onlyMail">只显示有电子邮件联系方式的记录</a-checkbox>
  </div>
  <table class="ahuiTable">
    <tr>
      <th>编号</th>
      <th>单号</th>
      <th>订单来源</th>
      <th>公司</th>
      <th>型号</th>
<!--      <th>厂商</th>-->
<!--      <th>批号</th>-->
<!--      <th>封装</th>-->
      <th>数量</th>
      <th>价格</th>
      <th>货币</th>
<!--      <th>邮件</th>-->
      <th>创建人</th>
      <th>创建时间</th>
      <th>24小时发送</th>
      <th></th>
    </tr>

    <template v-for="aRow in dataValue.rowObj['pp_list']">

      <tr :style="allEvent.rowColor.selectRowColor(aRow['indexId'])"
          @click="allEvent.rowColor.selectRowEvent(aRow['indexId'])"
          v-if="dataValue.onlyMail === false || aRow['linkmenEmail'].length > 5 || aRow['companyEmail'].length > 5 "
      >
        <td class="min">{{ aRow['indexId'] }}</td>
        <td class="min">{{ aRow['billId'] }}</td>
        <td class="min">{{ aRow['orderTypeName'] }}</td>

        <td>{{ aRow['companyName'] }}</td>

        <td>{{ aRow['modelName'] }}</td>
  <!--      <td>{{ aRow['manufacturerName'] }}</td>-->
  <!--      <td>{{ aRow['batchName'] }}</td>-->
  <!--      <td>{{ aRow['envelopName'] }}</td>-->

        <td class="number min">{{ aRow['number'] }}</td>
        <td class="price min">{{ parseFloat( aRow['price']).toFixed(3) }}</td>
        <td class="min">{{ aRow['moneyTypeName'] }}</td>

  <!--      <td>{{ aRow['linkmenEmail'] }}</td>-->

        <td class="min">{{ aRow['opEmployeeName'] }}</td>
        <td class="min">{{ aRow['createTime'] }}</td>
        <td class="min">{{ aRow['send24HourSize'] === 0 ? "-" : aRow['send24HourSize'] }}</td>
        <td class="min">
          <a v-if=" aRow['linkmenEmail'].length > 5 || aRow['companyEmail'].length > 5 "
           @click="allEvent.showEmail.goMOpen(aRow)">邮件</a>
        </td>
      </tr>

    </template>

  </table>

  <a-drawer
      title="联系发送邮件"
      width="780"
      :visible="dataValue.showEmail.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.showEmail.goMClose"
  >
    <up2BomShowEmail :row_email_obj="dataValue.showEmail.rowObj" :my_role_type="dataValue.myRoleType"
                     @goMClose="allEvent.showEmail.goMClose"/>
  </a-drawer>

</template>

<script>

import {reactive} from "vue";
import up2BomShowEmail from "@/view/uplater/up2_bom_show_email";
import tfun from "@/tools/tfun";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";

export default {
  name : "up2_bom_show_Email",
  components : {up2BomShowEmail},
  emits:['goClose'],
  props : [ 'row_obj', 'my_role_type' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      rowObj : props.row_obj,
      myRoleType : props.my_role_type,
      onlyMail : false,
      selectRow : 0,
      showEmail :{
        visible : false,
        rowObj : null
      }
    })

    let allEvent = {

      showEmail : {

        goMOpen : (row)=>{
          dataValue.showEmail.visible = true;
          dataValue.showEmail.rowObj = row;
        },

        goMClose :()=>{
          dataValue.showEmail.visible = false;
        }
      },

      rowColor : {
        selectRowColor : (rowId) => {
          return dataValue.selectRow === rowId ? "background-color:#d8ffd8;" : "";
        },
        selectRowEvent : (rowId) => {
          dataValue.selectRow = rowId;
        }
      },

      getSendMailSize : ()=>{

        for( let i=0; i<dataValue.rowObj['pp_list'].length; i++ ){

          let aRow = dataValue.rowObj['pp_list'][i];

          if( aRow['linkmenEmail'].length > 5 || aRow['companyEmail'].length > 5 ){

            let mailInput = { "mails": [ aRow['linkmenEmail'], aRow['companyEmail'] ] }

            proxy.newApi('/uplater/bom2/get.send.mail.size', mailInput).then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                aRow['send24HourSize'] = res.data['sendSize'];
              }
            })

          }

        }

      },


      init : ()=>{
        allEvent.getSendMailSize();
      }
    }

    allEvent.init();

    return {
      tfun, dataValue, allEvent
    }

  }
}
</script>


<style scoped>
  .onlyMailDiv{ position:fixed; top:20px; right: 20px; }
</style>